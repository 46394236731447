import { graphql } from 'gatsby';
import React from 'react';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import Benefit from 'components/molecules/Benefit';
import Feature from 'components/molecules/Feature';
import BenefitsSection from 'components/organisms/BenefitsSection';
import ConsumerRelationshipsSection from 'components/organisms/ConsumerRelationshipsSection';
import CtaSection from 'components/organisms/CtaSection';
import Hero from 'components/organisms/AnimationHero';
import FeaturesSection from 'components/organisms/FeaturesSection';
import TestimonialSection from 'components/organisms/TestimonialSection';
import TwoColumnBenefitsSection from 'components/organisms/TwoColumnBenefitsSection';
import getImageData from 'helpers/nf-image-data';
import Animation from '../../animations/engage-hero.json';
import * as ZeroSpendingIcon from 'images/zero-spending.inline.svg';
import * as DeployQuicklyIcon from 'images/deploy-quickly.inline.svg';

const heroImageData = getImageData('/images/fluid/engage-for-brands.jpg');

const EngageForBrandContent = ({
  hero,
  tagline,
  benefit,
  testimonial,
  twoImageBenefit,
  benefit2,
  buildRelationships,
  features,
  cta,
}) => (
  <>
    <Hero
      className="engage-hero"
      style={{ backgroundColor: '#F2F2F2' }}
      breadcrumb="Engage"
      heading={hero?.title}
      content={hero?.description}
      animationData={Animation}
      button={{
        to: hero?.primaryCta?.url,
        label: hero?.primaryCta?.label,
      }}
    />

    <BenefitsSection title={tagline?.title}>
      <Benefit
        title={benefit?.title}
        body={benefit?.body}
        image={benefit?.image}
        imageSide={benefit?.imageSide}
      >
        <div className="max-w-full mb-8 border-b mt-9 border-mid-grey border-opacity-30 w-72" />
        <div>
          <div className="inline-flex items-center mb-3">
            <ZeroSpendingIcon />
            <div className="mt-1 text-lg font-semibold leading-none lg:text-xl">
              {benefit?.footerHeading}
            </div>
          </div>
          <p className="max-w-full lg:text-lg md:w-96">{benefit?.footerBody}</p>
        </div>
      </Benefit>
    </BenefitsSection>

    <TestimonialSection
      quote={testimonial?.quote}
      ctaLabel="Read customer story"
      className="bg-dark-grey"
      inverted
      hasRectangle
    />

    <TwoColumnBenefitsSection
      title={twoImageBenefit?.title}
      body={twoImageBenefit?.body}
      image1Url={twoImageBenefit?.image1}
      image2Url={twoImageBenefit?.image2}
      footerHeading={twoImageBenefit?.footerHeading}
      footerBody={twoImageBenefit?.footerBody}
    />

    <BenefitsSection>
      <Benefit
        title={benefit2?.title}
        body={benefit2?.body}
        image={benefit2?.image}
        imageSide={benefit2?.imageSide}
      >
        <div className="max-w-full mb-8 border-b mt-9 border-mid-grey border-opacity-30 w-72" />
        <div>
          <div className="inline-flex items-center mb-3">
            <DeployQuicklyIcon />
            <div className="mt-1 text-lg font-semibold leading-none lg:text-xl">
              {benefit2?.footerHeading}
            </div>
          </div>
          <p className="max-w-full lg:text-lg md:w-96">
            {benefit2?.footerBody}
          </p>
        </div>
      </Benefit>
    </BenefitsSection>

    <ConsumerRelationshipsSection
      title={buildRelationships?.title}
      body={buildRelationships?.body}
    />

    <div className="pt-0 border-b md:border-none border-border-grey" />

    <FeaturesSection title={features?.title} body={features?.body}>
      {features?.features?.map(({ title, body, image }, index) => (
        <Feature key={index} title={title} body={body} image={image} />
      ))}
    </FeaturesSection>

    <CtaSection headline={cta?.headline} />
  </>
);

export const EngageForBrandPreview = ({ entry }) => (
  <EngageForBrandContent {...entry.get('data').toJS()} />
);

const EngageForBrandsPage = ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => {
  const { seo } = frontmatter;

  return (
    <Layout>
      <SEO
        title={seo?.title}
        description={seo?.description}
        openGraphImage={seo?.openGraphImage}
      />
      <EngageForBrandContent {...frontmatter} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query EngageForBrandsPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/enterprise/engage/" }) {
      frontmatter {
        seo {
          title
          description
          openGraphImage
        }
        hero {
          title
          description
          primaryCta {
            label
            url
          }
        }
        tagline {
          title
        }
        benefit {
          title
          body
          imageSide
          image
          footerHeading
          footerBody
        }
        testimonial {
          quote
        }
        twoImageBenefit {
          title
          body
          image1
          image2
          footerHeading
          footerBody
        }
        benefit2 {
          title
          body
          imageSide
          image
          footerHeading
          footerBody
        }
        buildRelationships {
          title
          body
        }
        features {
          title
          body
          features {
            title
            body
            image
          }
        }
        cta {
          headline
        }
      }
    }
  }
`;

export default EngageForBrandsPage;
