import React from 'react';

interface TwoColumnBenefitsSectionProps {
  title: string;
  body: string;
  image1Url: string;
  image2Url: string;
  footerHeading: string;
  footerBody: string;
}

const TwoColumnBenefitsSection: React.FC<TwoColumnBenefitsSectionProps> = ({
  title,
  body,
  image1Url,
  image2Url,
  footerHeading,
  footerBody,
}) => {
  return (
    <div className="u-section u-container text-center md:text-left">
      <div className="flex flex-col lg:flex-row w-150 mx-auto max-w-full lg:w-auto">
        <div className="flex-1 lg:mr-3">
          <div className="mx-6 lg:mx-14 lg:mt-24 mb-16">
            <h2 className="mb-4 u-h2 text-dark-grey">{title}</h2>
            <p className="text-lg lg:text-xl text-dark-grey">{body}</p>
          </div>
          <img src={image1Url} alt="Save time" className="w-full h-auto" />
        </div>
        <div className="flex-1 lg:ml-3 flex flex-col-reverse lg:flex-col">
          <img
            src={image2Url}
            alt="Create a promotion"
            className="w-full h-auto"
          />
          <div className="mt-10 mb-10 lg:mb-0 lg:mt-20 mx-6 lg:mx-14">
            <div className="inline-flex items-center mb-3">
              <svg
                className="mr-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="44"
                height="29"
                fill="none"
                viewBox="0 0 44 29"
              >
                <path
                  fill="#F8962F"
                  d="M0.583 0.577H43.788V28.528H0.583z"
                ></path>
                <g fill="#fff" clipPath="url(#clip0)">
                  <path d="M20.347 7.625c-2.9 0-6 2.2-6 6 0 3.7 6 9 6 9s6-5.3 6-9c0-3.8-3.1-6-6-6zm0 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path>
                  <path d="M28.347 13.624c0 1-.3 2.1-.8 3.2 1.3-1.5 2.8-3.6 2.8-5.3 0-3.1-2.6-4.9-4.9-4.9-.3 0-.7 0-1 .1 2.2 1.3 3.9 3.7 3.9 6.9z"></path>
                </g>
                <defs>
                  <clipPath id="clip0">
                    <path
                      fill="#fff"
                      d="M0 0H16V16H0z"
                      transform="translate(14.347 6.625)"
                    ></path>
                  </clipPath>
                </defs>
              </svg>
              <div className="mt-1 leading-none text-lg lg:text-xl font-semibold">
                {footerHeading}
              </div>
            </div>
            <p className="lg:text-lg md:w-96 max-w-full">{footerBody}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoColumnBenefitsSection;
