import React from 'react';

interface ConsumerRelationshipsSectionProps {
  title: string;
  body: string;
}

const ConsumerRelationshipsSection: React.FC<ConsumerRelationshipsSectionProps> = ({
  title,
  body,
}) => {
  return (
    <div className="u-section text-dark-grey">
      <div className="relative">
        <img
          src="/images/consumer-relationships-mobile-1.png"
          alt=""
          className="md:hidden w-full h-auto mb-16"
        />
        <img
          src="/images/consumer-relationships-bg.jpg"
          alt=""
          className="hidden md:block w-full h-auto"
        />
        <div className="md:absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
          <div className="md:w-96 lg:w-120 max-w-full mx-auto text-center px-6 my-6 md:px-0">
            <h2 className="u-h2 mb-3">{title}</h2>
            <p className="text-lg lg:text-xl">{body}</p>
          </div>
        </div>
        <img
          src="/images/consumer-relationships-mobile-2.png"
          alt=""
          className="md:hidden w-full h-auto mt-9"
        />
      </div>
    </div>
  );
};

export default ConsumerRelationshipsSection;
