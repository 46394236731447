// https://docs.netlify.com/large-media/transform-images/
// Only works for the image that check in to netlify large media
const breakpoints = [200, 400, 800, 1200, 1600];

const getImageData = (src) => ({
  src,
  srcSet: breakpoints
    .map((w) => `${src}?nf_resize=fit&w=${w} ${w}w`)
    .join(', '),
});

export default getImageData;
